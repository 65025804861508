import { useEffect, useState } from "react";

import { Button, Popover, Typography } from "antd";

import icons from "../../config/icons";

import DeleteConfirmButton from "../button/DeleteConfirmButton";

import Info from "./Info";
import Footer from "./Footer";
import Storage from "./Storage";
import LogsDrawer from "./LogsDrawer";
import UsersDrawer from "./UsersDrawer";

import styles from "./_server.module.scss";

export default function Server({ item, onDelete, type = "user" }) {
  let info = null;

  const [serverHost, setServerHost] = useState(item.ip);

  if (item.serverInfo) {
    info = JSON.parse(item.serverInfo);
  }

  const [isOpenLogsDrawer, setIsOpenLogsDrawer] = useState(false);

  useEffect(() => {
    const checkProtocol = item.ip.split(":")[0];
    if (checkProtocol == "https") {
      setServerHost(item.ip);
    } else {
      setServerHost(item.ip + ":" + item.port);
    }
  }, [item]);

  return (
    <>
      <div className={styles["server"]}>
        <div className={styles["header"]}>
          <div>
            <p>{item.name}</p>

            <Popover title={serverHost}>
              <Typography.Paragraph
                ellipsis={true}
                style={{ margin: 0, width: 270, color: "gray" }}
                copyable={{
                  tooltips: true,
                }}
              >
                {/* {item.ip}:{item.port} */}
                {serverHost}
              </Typography.Paragraph>
            </Popover>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignItems: "baseline",
            }}
          >
            <Button
              type="primary"
              icon={icons.LOGS}
              style={{
                marginRight: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
              onClick={() => setIsOpenLogsDrawer(true)}
            />
            <DeleteConfirmButton name="server" onConfirm={onDelete} />
          </div>
        </div>

        <Storage data={info?.storage} />

        <Info data={info} url={`${item.ip}:${item.port}`} type={type} />

        <Footer data={info} status={item.status} />
      </div>

      <LogsDrawer
        server={item}
        open={isOpenLogsDrawer}
        onClose={() => setIsOpenLogsDrawer(false)}
      />
    </>
  );
}
