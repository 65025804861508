import { Drawer } from "antd";
import { useEffect, useState } from "react";
import TableComponent from "../table/TableComponent";

export default function GpuDrawer({ open, onClose, gpu }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (gpu) {
      const process_info = gpu.processes?.process_info || [];

      setDataSource(process_info);
    }
  }, [gpu]);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: "Id",
      dataIndex: "pid",
      key: "pid",
      align: "center",
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },

    {
      title: "Name",
      dataIndex: "process_name",
      key: "process_name",
      align: "center",
    },

    {
      title: "Memory",
      dataIndex: "used_memory",
      key: "used_memory",
      align: "center",
    },
  ];

  return (
    <Drawer
      title={gpu?.product_name}
      placement="right"
      onClose={onClose}
      open={open}
      width={800}
    >
      <TableComponent dataSource={dataSource} columns={columns} />
    </Drawer>
  );
}
